import React from 'react'
import Form from 'react-bootstrap/Form'
import FormCheck from 'react-bootstrap/FormCheck'
import FormGroup from 'react-bootstrap/FormGroup'
import Col from 'react-bootstrap/Col'
import MeasurementTypes from './measurement-types'
import WalkingBehaviorTypes from './walking-behavior-types'
import { REPORTERS, FULL_DATA } from './reporter-types'
import naturalCompare from 'string-natural-compare'

class MeasurementStep extends React.PureComponent {

    constructor(props) {
        super(props);

        this.setNetworkAccess = () => {
            this.props.setType(MeasurementTypes.NETWORK_ACCESS);
            this.props.clearFullReporterType();
        }

        this.setPointAccess = () => {
            this.props.setType(MeasurementTypes.POINT_ACCESS);
        }

        this.setSectorAccess = () => {
            this.props.setType(MeasurementTypes.SECTOR_ACCESS);
        }

        this.setCenterPoint = (event) => {
            this.props.setCenterPoint(event.target.value);
        }

        this.setCenterSector = (event) => {
            this.props.setCenterSector(event.target.value)
        }

        this.setName = (event) => {
            this.props.setName(event.target.value);
        }

        this.setWalkingBehaviorType = (event) => {
            this.props.setWalkingBehaviorType(event.target.id);
        }
    }

    render() {
        return (
            <>
                <Form>
                    <FormGroup controlId="name">
                        <Form.Label>Calculation name</Form.Label>
                        <Form.Control type="text"
                            value={this.props.name}
                            onChange={this.setName} />
                    </FormGroup>
                    <FormGroup controlId="center">
                        <Form.Label>Reach Probability Measurement</Form.Label>
                        <Col>
                            <FormCheck type="radio"
                                id="fullNetwork"
                                label="Full Network Reach Probability"
                                checked={this.props.calculationType
                                    === MeasurementTypes.NETWORK_ACCESS}
                                onChange={this.setNetworkAccess} />
                        </Col>
                        <Col>
                            <FormCheck type="radio"
                                id="point"
                                label="Point Reach Probability"
                                checked={this.props.calculationType
                                    === MeasurementTypes.POINT_ACCESS}
                                onChange={this.setPointAccess} />
                        </Col>
                        <Col>
                            <Form.Control size="sm" type="text"
                                disabled={this.props.calculationType
                                    !== MeasurementTypes.POINT_ACCESS}
                                placeholder="Select Point"
                                value={this.props.centerPoint}
                                onChange={this.setCenterPoint} />
                        </Col>
                        <Col>
                            <FormCheck type="radio"
                                id="sector"
                                label="Sector Reach Probability"
                                checked={this.props.calculationType
                                    === MeasurementTypes.SECTOR_ACCESS}
                                onChange={this.setSectorAccess} />
                        </Col>
                        <Col>
                            <Form.Control size="sm" type="text"
                                disabled={this.props.calculationType
                                    !== MeasurementTypes.SECTOR_ACCESS}
                                placeholder="Select Sector"
                                value={(this.props.centerSector === undefined)
                                    ? "" : this.props.centerSector}
                                onChange={this.setCenterSector} />
                        </Col>
                    </FormGroup>
                </Form>
                <Form>
                    {REPORTERS
                        .sort((n1, n2) => naturalCompare(n1, n2))
                        .map((name, type) => {
                            const isInvalid = (type === FULL_DATA && this.props.calculationType === MeasurementTypes.NETWORK_ACCESS);
                            return (
                                <FormGroup key={type}>
                                    <FormCheck
                                        id={`${type}-check`}
                                        label={name}
                                        disabled={isInvalid}
                                        type="checkbox"
                                        checked={this.props.selectedReporters.contains(type)}
                                        onChange={() => this.props.toggleReporterSelection(type)} />
                                </FormGroup>
                            )
                        }).valueSeq()}
                </Form>
                <Form>
                    <Form.Label>Walking Behavior Type</Form.Label>
                    <FormGroup>
                        <FormCheck type="radio"
                            id={WalkingBehaviorTypes.FLAT}
                            label="Flat"
                            checked={this.props.walkingBehaviorType === WalkingBehaviorTypes.FLAT}
                            onChange={this.setWalkingBehaviorType} />
                        <FormCheck type="radio"
                            id={WalkingBehaviorTypes.TOBLER}
                            label="Tobler"
                            checked={this.props.walkingBehaviorType === WalkingBehaviorTypes.TOBLER}
                            onChange={this.setWalkingBehaviorType} />
                    </FormGroup>
                </Form>
            </>
        );
    }
}

export default MeasurementStep