import React from 'react'

import { Map } from 'immutable';

import InfoPanel from './info-panel'
import Container from 'react-bootstrap/Container'
import AccessMap, { SECTOR_SELECTION_MODE } from './map'
import {
    setCalculationView,
    getClearedCalculationState,
    isEntirelyExhaustive,
} from './calculation'
import {
    setViewport,
    setSector,
    setExpandedSector,
    clearExpandedSector,
    setTransformationType,
    updateAggregationsForDuration,
} from './access-management'
import { RingSpinnerOverlay } from 'react-spinner-overlay';
import { DefaultColorScheme } from './color';

class AccessViewer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showInfo: this.props.initialShowInfo,
            expansionUpdateInterval: undefined,
            durationUpdateInterval: undefined,

            "sectors": [],
            "gridId": undefined,
            "gridName": undefined,
            "gridCenter": undefined,

            "serviceId": undefined,
            "earliestTime": undefined,
            "latestTime": undefined,

            "calculation": Map(getClearedCalculationState()),
            "baseline": Map(getClearedCalculationState()),

            "selectedSector": undefined,
            "boundsSelection": undefined,
            "clickedLocation": undefined,

            "expandedSector": undefined,

            "loadingExpansion": false,
            "changingDuration": false,

            "selectedSectorAggregation": undefined,

            "viewport": {
                "latitude": 39.8283,
                "longitude": -98.5795,
                "zoom": 13,
            },

            "transformationType": undefined,
            "sectorTransformations": Map(),
            "expandedSectorTransformations": Map(),
            "colorScheme": new DefaultColorScheme(),

            inServiceSeconds: 0,
            baselineInServiceSeconds: 0
        };

        this.loadCalculationPromise = (calculation, calculationPath, baseline,
            baselinePath) => setCalculationView(this, calculation,
                calculationPath, baseline, baselinePath);

        this.setViewport = (viewport) => setViewport(this, viewport);

        this.setSector = (sectorId) => setSector(this, sectorId);

        this.setExpandedSector = (sectorId) => setExpandedSector(this, sectorId);

        this.clearExpandedSector = () => clearExpandedSector(this);

        this.setTransformationType = (type) => setTransformationType(this, type);

        this.setDuration = (duration) => {
            updateAggregationsForDuration(
                this,
                duration,
                this.state.calculation.get("id"),
                this.state.calculation.get("path"),
                "calculation",
                "inServiceSeconds");
        }

        this.setBaselineDuration = (duration) => {
            updateAggregationsForDuration(
                this,
                duration,
                this.state.baseline.get("id"),
                this.state.baseline.get("path"),
                "baseline",
                "baselineInServiceSeconds");
        }

        this.closeInfo = () => {
            this.setState({ "showInfo": false });
        }

    }

    componentDidMount() {
        this.loadCalculationPromise(this.props.calculationId, this.props.path,
            this.props.baselineCalculationId, this.props.baselinePath);
    }

    render() {

        return (
            <>
                <RingSpinnerOverlay
                    loading={this.state.sectorTransformations.isEmpty()}
                    color="#000000" />
                <Container fluid style={{
                    "display": "flex",
                    "flexDirection": "column",
                    "flexGrow": 1
                }}>

                    <h1>{this.state.calculation.get("name")}</h1>
                    <InfoPanel
                        colorScheme={this.state.colorScheme}
                        transformationType={this.state.transformationType}
                        sectors={this.state.sectors}
                        selectedSector={this.state.selectedSector}
                        expandedSector={this.state.expandedSector}
                        setSector={this.setSector}
                        calculation={this.state.calculation}
                        baseline={this.state.baseline}
                        unScoreIt={this.props.unScoreIt}
                        setDuration={this.setDuration}
                        setBaselineDuration={this.setBaselineDuration}
                        inServiceSeconds={this.state.inServiceSeconds}
                        baselineInServiceSeconds={this.state.baselineInServiceSeconds} />
                    <AccessMap
                        minRouteZoom={14}
                        stops={this.state.calculation.get("stops")}
                        linestrings={this.state.calculation.get("linestrings")}
                        routesForRoutings={this.state.calculation.get("routesForRoutings")}
                        gridId={this.state.gridId}
                        colorScheme={this.state.colorScheme}
                        sectorTransformations={this.state.sectorTransformations}
                        expandedSectorTransformations
                        ={this.state.expandedSectorTransformations}
                        transformationType={this.state.transformationType}
                        gridCenter={this.state.gridCenter}
                        calculationCenterPoints
                        ={this.state.calculation.get('centerPoints')}
                        baselineCenterPoints
                        ={this.state.baseline.get('centerPoints')}
                        calculationCenterSectors
                        ={this.state.calculation.get('centerSectors')}
                        baselineCenterSectors
                        ={this.state.baseline.get('centerSectors')}
                        setSector={this.setSector}
                        selectedSector={this.state.selectedSector}
                        expandedSector={this.state.expandedSector}
                        busy={this.state.loadingExpansion || this.state.changingDuration}
                        setExpandedSector={this.setExpandedSector}
                        clearExpandedSector={this.clearExpandedSector}
                        unGridIt={this.props.unGridIt}
                        showSelect={!this.state.gridId}
                        selectionMode={SECTOR_SELECTION_MODE}
                        viewport={this.state.viewport}
                        setViewport={this.setViewport}
                        isComparison={!!this.state.baseline.get('id')}
                        isExhaustive={isEntirelyExhaustive(
                            this.state.calculation, this.state.baseline)}
                        setTransformationType={this.setTransformationType}
                        boundaryFindingMode={false} />
                    <small><a href={process.env.PUBLIC_URL + "/Open Source Disclosure.pdf"}>Open source disclosure</a></small>
                </Container>
            </>);
    }
}

export default AccessViewer;