import React from 'react'

import GridStep from './grid-step'
import TimeParametersStep from './time-parameters-step'
import ServiceStep from './service-step'
import MeasurementStep from './measurement-step'

import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Nav from 'react-bootstrap/Nav'
import Spinner from 'react-bootstrap/Spinner'

import { Set, Map } from 'immutable'

import { LocalDate, LocalTime, Duration, ChronoUnit } from '@js-joda/core'
import MeasurementTypes from './measurement-types'
import { FULL_DATA } from './reporter-types'


class CreationWizard extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            "step": 0,
            "maxStep": 0,

            "stageWaiting": false,

            "gridName": "",
            "gridResolution": 80,
            "selectedBoundaries": Map(),

            "durationValue": "30",
            "durationUnit": "M",
            "day": "",

            "selectedNetworks": Set(),

            "calculationName": "",

            "selectedReporters": Set(),

            "baseTime": undefined,
            "gridsRefreshInterval": undefined,
            "walkingBehaviorType": undefined,
        };

        this.updateStep = (step) => {
            const newStep = parseInt(step);
            const newMax = (newStep > this.state.maxStep) ?
                newStep : this.state.maxStep;
            this.setState({ "step": newStep, "maxStep": newMax });
        };

        this.setStep = (step) => {
            this.updateStep(step);
        };

        this.goToNextStep = () => {
            this.updateStep(this.state.step + 1);
        };

        this.setGridName = (event) => {
            this.setState(
                { "gridName": event.target.value })
        };


        this.setGridResolution = (event) => {
            this.setState(
                { "gridResolution": event.target.value })
        };

        this.setChosenGrid = (chosen) => {
            if (chosen === '') {
                this.props.clearGrid();
            } else {
                this.props.setGrid(chosen);
            }
        };

        this.setDurationValue = (event) => {
            this.setState({ "durationValue": event.target.value });
        };

        this.setDurationUnit = (event) => {
            this.setState({ "durationUnit": event.target.value });
        };

        this.setDay = (moment) => {
            this.setState({ "day": moment });
        };

        this.setChosenService = (chosen) => {
            if (chosen === '') {
                this.props.clearService();
            } else {
                this.props.setService(chosen);
            }
        };

        this.toggleNetworkSelection = (network) => {
            if (this.state.selectedNetworks.contains(network)) {
                this.setState({
                    "selectedNetworks":
                        this.state.selectedNetworks.remove(network)
                });
            } else {
                this.setState({
                    "selectedNetworks":
                        this.state.selectedNetworks.add(network)
                });
            }
        };

        this.clearFullReporterType = () => {
            this.setState({
                "selectedReporters":
                    this.state.selectedReporters.remove(FULL_DATA)
            });
        };

        this.toggleReporterSelection = (type) => {
            if (this.state.selectedReporters.contains(type)) {
                this.setState({
                    "selectedReporters":
                        this.state.selectedReporters.remove(type)
                });
            } else {
                this.setState({
                    "selectedReporters":
                        this.state.selectedReporters.add(type)
                });
            }
        };

        this.handleGridNext = () => {
            if (!this.props.gridId) {
                const boundaryIds
                    = this.state.selectedBoundaries.keySeq().toArray();
                this.createGrid(this.props.tokenProvider, this.state.gridName,
                    boundaryIds, this.state.gridResolution);
                this.setState({ "stageWaiting": true })
            }
            this.goToNextStep();
        };

        this.handleServiceNext = () => {
            this.goToNextStep();
        };

        this.handleMeasurementNext = () => {
            const day = this.state.day;
            const durationValueStrings = this.state.durationValue.split(",");
            const unit = (this.state.durationUnit === "M")
                ? ChronoUnit.MINUTES : ChronoUnit.HOURS;

            const baseDay = LocalDate.parse(day.format("YYYY-MM-DD"))
            let baseTime;
            if (this.state.baseTime === undefined) {
                baseTime = baseDay.atStartOfDay();
            } else {
                const time = LocalTime.parse(this.state.baseTime.format("HH:mm"));
                baseTime = baseDay.atTime(time);
            }
            const span = (this.state.baseTime !== undefined) ? 60 : 86400;
            const durations =
                durationValueStrings.map(duration => {
                    return Duration.of(parseInt(duration.trim()), unit).seconds();
                });
            const centerSectorIndex = this.props.centerSector - 1;
            const centerPoints = (this.props.centerPoint) ? [this.props.centerPoint] : [];
            const networks = this.state.selectedNetworks.toArray();
            const reporters = this.state.selectedReporters.toArray();

            if (this.props.calculationType
                === MeasurementTypes.POINT_ACCESS) {
                this.props.unScoreIt.createPointAccessCalculation(
                    this.props.tokenProvider, this.state.calculationName,
                    this.props.gridId, networks, baseTime, span,
                    60, durations, centerPoints, reporters,
                    this.state.walkingBehaviorType).then((res) => {
                        this.props.setChosen(res.data,
                            MeasurementTypes.POINT_ACCESS);
                    });
            } else if (this.props.calculationType
                === MeasurementTypes.SECTOR_ACCESS) {
                this.props.unScoreIt.createSectorAccessCalculation(
                    this.props.tokenProvider, this.state.calculationName,
                    this.props.gridId, networks, baseTime, span,
                    60, durations, centerSectorIndex, reporters,
                    this.state.walkingBehaviorType).then((res) => {
                        this.props.setChosen(res.data,
                            MeasurementTypes.POINT_ACCESS);
                    });
            } else if (this.props.calculationType
                === MeasurementTypes.NETWORK_ACCESS) {
                this.props.unScoreIt.createNetworkAccessCalculation(
                    this.props.tokenProvider, this.state.calculationName,
                    this.props.gridId, networks, baseTime, span,
                    60, durations, reporters,
                    this.state.walkingBehaviorType).then((res) => {
                        this.props.setChosen(res.data,
                            MeasurementTypes.NETWORK_ACCESS);
                    });
            }
        };

        this.setCenter = (center) => {
            this.setState({ "center": center });
        };

        this.setCalculationName = (name) => {
            this.setState({ "calculationName": name });
        };

        this.addBoundary = (id, name) => {
            this.setState({
                "selectedBoundaries":
                    this.state.selectedBoundaries.set(id, name)
            })
        };

        this.removeBoundary = (id) => {
            this.setState({
                "selectedBoundaries":
                    this.state.selectedBoundaries.remove(id)
            })
        };

        this.setBaseTime = (baseTime) => {
            this.setState({ "baseTime": baseTime })
        };

        this.clearBaseTime = () => {
            this.setState({ "baseTime": undefined })
        };

        this.setWalkingBehaviorType = (type) => {
            this.setState({ "walkingBehaviorType": type })
        };

        this.createGrid = (tokenProvider, name, boundaries, resolution) => {
            this.props.unGridIt.createGrid(tokenProvider, name, boundaries, resolution).then((res) => {
                const interval = setInterval(this.waitForGrid, 30_000, res.data);
                this.setState({ "gridsRefreshInterval": interval });
            });
        };

        this.waitForGrid = (gridId) => {
            this.props.unGridIt.getGrids().then((resp) => {
                if (resp.data.map(item => item.id).includes(gridId)) {
                    clearInterval(this.state.gridsRefreshInterval);
                    this.props.setGrid(gridId);
                    this.setState({
                        "gridsUpdateInterval": undefined,
                        "stageWaiting": false,
                    });
                }
            });
        }
    }



    render() {
        const steps = [
            {
                "name": "Grid",
                "component": <GridStep
                    gridId={this.props.gridId}
                    gridName={this.props.gridName}
                    sectorCount={this.props.sectorCount}
                    tokenProvider={this.props.tokenProvider}
                    unGridIt={this.props.unGridIt}
                    boundsSelection={this.props.boundsSelection}
                    name={this.state.gridName}
                    resolution={this.state.gridResolution}
                    setName={this.setGridName}
                    setResolution={this.setGridResolution}
                    setChosen={this.setChosenGrid}
                    boundaries={this.props.boundaries}
                    selectedBoundaries={this.state.selectedBoundaries}
                    addBoundary={this.addBoundary}
                    removeBoundary={this.removeBoundary} />,
                "nextAction": this.handleGridNext
            },
            {
                "name": "Time Parameters",
                "component": <TimeParametersStep
                    durationValue={this.state.durationValue}
                    durationUnit={this.state.durationUnit}
                    day={this.state.day}
                    baseTime={this.state.baseTime}
                    setDurationValue={this.setDurationValue}
                    setDurationUnit={this.setDurationUnit}
                    setDay={this.setDay}
                    setBaseTime={this.setBaseTime}
                    clearBaseTime={this.clearBaseTime} />,
                "nextAction": this.goToNextStep
            },
            {
                "name": "Transit Service",
                "component": <ServiceStep
                    serviceId={this.props.serviceId}

                    selectedNetworks={this.state.selectedNetworks}

                    setChosen={this.setChosenService}
                    toggleNetworkSelection={this.toggleNetworkSelection}

                    tokenProvider={this.props.tokenProvider}
                    unRideIt={this.props.unRideIt} />,
                "nextAction": this.handleServiceNext
            },
            {
                "name": "Measurement",
                "component": <MeasurementStep
                    centerPoint={this.props.centerPoint}
                    centerSector={this.props.centerSector}
                    setType={this.props.setType}
                    setCenterPoint={this.props.setCenterPoint}
                    setCenterSector={this.props.setCenterSector}
                    name={this.state.calculationName}
                    setName={this.setCalculationName}
                    calculationType={this.props.calculationType}
                    selectedReporters={this.state.selectedReporters}
                    toggleReporterSelection={this.toggleReporterSelection}
                    clearFullReporterType={this.clearFullReporterType}
                    walkingBehaviorType={this.state.walkingBehaviorType}
                    setWalkingBehaviorType={this.setWalkingBehaviorType} />,
                "nextAction": this.handleMeasurementNext
            }
        ];

        return (
            <Tab.Container id="wizard" activeKey={this.state.step}
                onSelect={this.setStep}>
                <Row>
                    <Col sm={2}>
                        <Nav variant="pills" className="flex-column">
                            {steps.map((step, index) => {
                                return (
                                    <Nav.Item key={"calculationwizardstep-" + index}>
                                        <Nav.Link
                                            disabled={index > this.state.maxStep}
                                            eventKey={index}>
                                            {step.name}
                                        </Nav.Link>
                                    </Nav.Item>)
                            })}
                        </Nav>
                    </Col>
                    <Col sm={10}>
                        <Tab.Content>
                            {steps.map((step, index) => {
                                const content = this.state.stageWaiting ?
                                    (<Spinner animation="border" />) :
                                    (<>
                                        {step.component}
                                        <Button onClick={step.nextAction}>Next</Button>
                                    </>);

                                return (
                                    <Tab.Pane key={"calculationwizardpane-" + index}
                                        eventKey={index}>
                                        {content}
                                    </Tab.Pane>);
                            })}

                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>);
    }
}

export default CreationWizard