import React from 'react'
import { Badge } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'

import naturalCompare from 'string-natural-compare'

class GridStep extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            grids: []
        }

        this.loadGrids = () => {
            this.props.unGridIt.getGrids().then((res) => {
                const listItems = res.data.map((grid) => {
                    return { id: grid['id'], name: grid['name'] };
                });
                this.setState({ grids: listItems });
            });
        }
    }

    render() {
        const creator = (
            <GridCreator
                tokenProvider={this.props.tokenProvider}
                loadGrids={this.loadGrids}
                setChosen={this.props.setChosen}
                unGridIt={this.props.unGridIt}
                boundsSelection={this.props.boundsSelection}
                name={this.props.name}
                resolution={this.props.resolution}
                boundaries={this.props.boundaries}
                selectedBoundaries={this.props.selectedBoundaries}
                setName={this.props.setName}
                setResolution={this.props.setResolution}
                addBoundary={this.props.addBoundary}
                removeBoundary={this.props.removeBoundary} />);

        const infoTable = (
            <GridInfo
                gridName={this.props.gridName}
                sectorCount={this.props.sectorCount} />
        )

        return (
            <div>
                <GridChooser
                    selectedGrid={this.props.gridId}
                    grids={this.state.grids}
                    tokenProvider={this.props.tokenProvider}
                    unGridIt={this.props.unGridIt}
                    setGrid={this.props.setGrid}
                    clearGrid={this.props.clearGrid}
                    boundsSelection={this.props.boundsSelection}
                    loadGrids={this.loadGrids}
                    setChosen={this.props.setChosen} />

                {(this.props.gridId) ? infoTable : creator}
            </div>);
    }
}

class GridInfo extends React.PureComponent {
    render() {
        return (
            <Table bordered size="sm">
                <tbody>
                    <tr>
                        <td>Sectors</td>
                        <td>{this.props.sectorCount}</td>
                    </tr>
                </tbody>
            </Table>);
    }
}

class GridCreator extends React.Component {

    constructor(props) {
        super(props);

        this.addBoundary = (event) => {
            const id = event.target.id;
            const name = event.target.textContent;
            this.props.addBoundary(id, name);
        }

        this.removeBoundary = (event) => {
            const id = event.target.id;
            this.props.removeBoundary(id);
        }
    }

    render() {
        const visibleBoundaries = this.props.boundaries
            .sort()
            .filter((v, k) => !this.props.selectedBoundaries.has(k))
            .map((name, id) => {
                return (<Badge bg="secondary" key={id} id={id} onClick={this.addBoundary}>{name}</Badge>);
            }).valueSeq();

        const selectedBoundaries = this.props.selectedBoundaries.map((name, id) => {
            return (<Badge key={id} id={id} onClick={this.removeBoundary}>{name}</Badge>);
        }).valueSeq();

        return (
            <>
                <Form>
                    <Form.Group controlId="gridName">
                        <Form.Label>Grid Name</Form.Label>
                        <Form.Control type="text" value={this.props.name}
                            onChange={this.props.setName} />
                    </Form.Group>
                    <Form.Group controlId="resolution">
                        <Form.Label>Resolution</Form.Label>
                        <Form.Control type="number" value={this.props.resolution}
                            onChange={this.props.setResolution} />
                        <Form.Text className="textMuted">Meters</Form.Text>
                    </Form.Group>
                </Form>
                <div style={{ overflow: "auto", height: "60px" }}>
                    {visibleBoundaries}
                </div>
                <div style={{ overflow: "auto", height: "60px" }}>
                    {selectedBoundaries}
                </div>
            </>
        );
    }
}

class GridChooser extends React.PureComponent {

    constructor(props) {
        super(props);

        this.handleSelect = (event) => {
            const id = event.target.value;
            this.props.setChosen(id);
        }
    }

    componentDidMount() {
        this.props.loadGrids();
    }

    render() {
        const value = this.props.selectedGrid ?
            this.props.selectedGrid : "";

        return (
            <div className="grid-chooser">
                <h4>Grids</h4>
                <Form>
                    <Form.Select value={value}
                        onChange={this.handleSelect}>
                        <option key="" value="">Create new</option>
                        {this.props.grids
                            .sort((g1, g2) => naturalCompare(
                                g1['name'].toLowerCase(),
                                g2['name'].toLowerCase()))
                            .map((grid) => {
                                return (<option key={grid['id']} value={grid['id']}>{grid['name']}</option>);
                            })}
                    </Form.Select>
                </Form>
            </div>
        );
    }
}


export default GridStep